import React from "react";
import PropTypes from "prop-types";
import AddUpdateLineForm from "../../forms/AddUpdateLineForm";

const LineUpdatePage = ({ location }) => (
  <AddUpdateLineForm location={location} />
);

LineUpdatePage.propTypes = {
  location: PropTypes.shape({}),
};

LineUpdatePage.defaultProps = {
  location: {},
};

export default LineUpdatePage;
