import React from "react";
import { Router } from "@reach/router";

import LineListingPage from "../components/PageComponents/LineListingPage";
import LineAddPage from "../components/PageComponents/LineAddPage";
import LineUpdatePage from "../components/PageComponents/LineUpdatePage";

const lines = (props) => (
  <Router>
    <LineListingPage {...props} path="/lines" />
    <LineAddPage {...props} path="/lines/add-line" />
    <LineUpdatePage {...props} path="/lines/:lineId" />
  </Router>
);

export default lines;
