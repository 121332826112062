import { get } from "lodash";
import { ALL_OPTION } from "./constants";

const search = (dataArray, searchValue, searchAttribute) =>
  dataArray
    .filter(
      (data) =>
        get(data, `${searchAttribute}`)
          .toLowerCase()
          .includes(searchValue.toLowerCase()) || searchValue === ALL_OPTION
    )
    .map((data) => data);

export default search;
